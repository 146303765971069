import styled from "styled-components";
import tw from "twin.macro";
import  { palette, theme } from "../Styles/Palette";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const ProfileSection = styled.div`
${tw``}
display: flex;
float: right;
   align-items: center;
    flex-direction: column;
    justify-content: space-between;
`; 

export const Navbar = styled.div`
${tw `fixed px-4 py-4 h-20 w-full flex items-center justify-between gap-4`}
background: ${palette.primary};
`;

export const NavLogo = styled.img`
//  ${tw `sm:w-20`}
width: 60px;  
`;

export const NavTitle = styled.h1`
${tw `text-white text-center text-xl lg:text-3xl font-bold `}
`;

export const ProfileDiv = styled.div`
 ${tw `bg-white rounded-lg absolute top-[110%]`}
 box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

export const ProfileButton = styled.button`
${tw `block px-8 py-2 cursor-pointer`}
`;

// export const StyledAccountCircleIcon = styled(AccountCircleIcon)`
//     font-size: 42px;
//     color: white;
//     cursor: pointer;
//     float: right;
//     margin-top: -55px;
//     margin-right: 34px'
// `;

// export const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)`
// color: white;
// cursor: pointer;
// float: right;
// font-size: 32px;
// margin-top: -45px;
// margin-right: 5px;
// `;

// export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
// color: white;
// cursor: pointer;
// float: right;
// font-size: 32px;
// margin-top: -45px;
// margin-right: 5px;

// `;
// style={{ display: 'block', padding: '10px 30px', cursor: 'pointer' }}