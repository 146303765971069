import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ backgroundColor }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back one step in the history stack
  };

  return (
    <IconButton
      sx={{
        color: 'black',
        '&:hover': {
          backgroundColor: '#eee', // Removes the hover effect
        },
        backgroundColor,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={handleBack}
    >
      <ArrowBackIosIcon style={{ color: 'black' }} />
    </IconButton>
  );
};

export default BackButton;
