import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import EventNavbar from "./Assets/EventNavbar";
import PageNotFound from "./Assets/PageNotFound";
import RowUsers from "./Users/Users";
import HistoryofImports from "./Users/HistoryofImports";

//TODO: AddED  lazy loading for components
const Event = React.lazy(() => import("./Assets/Event"));
const CreateOrEditEvent = React.lazy(() => import("./Assets/CreateEvent"));
const AddInstructor = React.lazy(() => import("./Assets/AddInstructor"));
const ViewEventDetails = React.lazy(() => import("./Assets/ViewEventDetails"));
const UserDetails = React.lazy(() => import("./Users/UserDetails"));
const DemoLogin = React.lazy(() => import("./Login/DemoLogin"));
const AddUserDetails = React.lazy(() => import("./Users/AddUserDetails"));
const MyProfile = React.lazy(() => import("./My Profile/MyProfile"));
const UpdateUser = React.lazy(() => import("./Users/UpdateUser"));
const DumyTableLearn = React.lazy(() => import("./Assets/DumyTableLearn"));

const App = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <BrowserRouter>
        {user?.user && <EventNavbar />}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {user?.user ? (
              <>
                <Route path="/event" element={<Event />} />
                <Route path="/userDetails" element={<UserDetails />} />
                <Route path="/create" element={<CreateOrEditEvent />} />
                <Route
                  path="/CreateOrEditEvent"
                  element={<CreateOrEditEvent />}
                />
                <Route path="/update/:id" element={<CreateOrEditEvent />} />
                <Route path="/addInstructor" element={<AddInstructor />} />
                <Route path="/view/:id" element={<ViewEventDetails />} />
                <Route path="/addUser" element={<AddUserDetails />} />
                <Route path="/profile" element={<MyProfile />} />
                <Route path="/updateUser/:id" element={<UpdateUser />} />
                <Route path="/table" element={<DumyTableLearn />} />
                <Route
                  path="/users/UserDetails/:userId"
                  element={<RowUsers />}
                />
                <Route
                  path="/users/HistoryofImports"
                  element={<HistoryofImports />}
                />
              </>
            ) : (
              <Route path="/" element={<DemoLogin />} />
            )}
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
