import axios from "axios";
// const URL = 'https://leapot-lms-backend.onrender.com/api';
// const URL= 'http://localhost:8000/api'

import { postData, getData } from "./CustomApiCalling";
import { getHeader } from "./localStorageUtils";

export const createEventApi = async (formData) => {
  try {
    const response = await postData(
      `${process.env.REACT_APP_URL}/api/eventManager/createEvent`,
      formData
    );
    return response.data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error creating event:", error);
    throw error; // Re-throw the error for further handling
  }
};

export const fetchEventApi = async () => {
  try {
    const customHeaders = getHeader();

    const response = await getData(
      `${process.env.REACT_APP_URL}/api/eventManager/getEvents`,
      customHeaders
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchEventDetailsApi = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/eventManager/getEventById/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const updateEventApi = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL}/api/eventManager/updateEvent/${data._id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const deleteEventApi = async (id) => {
  try {
    await axios.patch(
      `${process.env.REACT_APP_URL}/api/eventManager/deleteEvent/${id}`
    );
    return id; // Return the eventId if deletion is successful
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const viewEventDetailsApi = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/eventManager/getEventById/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

//Instructor
export const createInstructorApi = async ({ Name: instructorName }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/eventManager/addInstructor`,
      { Name: instructorName }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};
export const fetchInstructorApi = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/eventManager/getInstructor`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

//Users
export const createUserApi = async (UserData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/eventManager/users`,
      UserData
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const fetchUserApi = async () => {
  try {
    const customHeaders = getHeader();
    const response = await getData(
      `${process.env.REACT_APP_URL}/api/eventManager/getUsers`,
      customHeaders
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserApi = async (data) => {
  try {
    const response = await postData(
      `${process.env.REACT_APP_URL}/api/userProfile`,
      data
    );
    console.log("update api", response.data);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const fetchUserDetailsApi = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_URL}/api/eventManager/getUser/${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteUserApi = async (id) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_URL}/api/eventManager/deleteUser/${id}`
    );
    return id; // Return the eventId if deletion is successful
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const deleteAllUserApi = async (selectedRows) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL}/api/eventManager/deleteAllUser/`,
      {
        data: {
          rows: selectedRows.map((row) => row._id), // Assuming each row has an 'id' property
        },
      }
    );
    return response.data; // Return the eventId if deletion is successful
  } catch (error) {
    throw new Error(error.response.data);
  }
};
