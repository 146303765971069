import styled from 'styled-components';
import tw from 'twin.macro';
// import { palette } from '../palette';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { palette } from './Palette';

/* --------------------Styling present on frontpg Calendar------------------------------------*/

export const CalendarMainContainer = styled.div`
  ${tw`w-full`}
`;

export const UpperContainer = styled.div`
  ${tw`w-full`}
  margin-top:60px;
`;
export const CalendarContainer = styled.div`
  ${tw` `}
  /* padding: 10px;  */
  margin-top: 50px;
  margin-left: 2rem;
`;

export const DisplayTextCalendar = styled.div`
  ${tw`text-blue-600 text-sm p-1 ml-10`}
`;
export const DisplayTextAnn = styled.div`
  ${tw`text-blue-600 text-sm p-1 ml-14`}
`;

export const DisplayText = styled.div`
  ${tw`text-sm sm:text-base tracking-normal sm:tracking-wider leading-relaxed text-justify md:text-left lg:ml-8`}
`;

/* --------------------Styling present events calendar------------------------------------*/
export const CalendarComp = styled.div``;

export const CalendarM = styled.div`
  ${tw`block text-center m-1 lg:m-2 xl:m-2 xl:p-1 shadow-2xl`}
`;
export const MonthName = styled.h2`
  ${tw``}
`;
export const CalTable = styled.table`
  ${tw`cursor-pointer p-2`}
`;
export const CalTableTH = styled.thead`
  ${tw``}
`;
export const CalTableTR = styled.tr`
  ${tw``}
`;
export const CalTableth = styled.th`
  ${tw`p-[15px] xl:p-[25px]`}
`;
export const CalTabRow = styled.tbody`
  ${tw``}
`;

/* --------------------Styling present on Image Slider------------------------------------*/

export const ImgText = styled.div`
  ${tw`flex justify-center items-center p-5 font-bold text-xl sm:text-2xl`}
`;
export const InputText = styled.input`
  ${tw`flex justify-center items-center p-2 w-72 rounded border-none sm:p-3 sm:w-[500px] md:w-[650px] lg:w-[800px]`}
`;
export const UpdatesImg = styled.div`
  ${tw`flex justify-center items-center`}
`;

export const ImageSlidebtn = styled.button`
  ${tw`flex bg-[#fff] border-none p-1 m-1 lg:p-3 mt-6 font-bold rounded-full  text-2xl cursor-pointer`}
`;
export const AnchorTag = styled.a`
  ${tw`flex justify-center items-center`}
`;

export const ImageTag = styled.img`
  /* ${tw`mt-12 w-[240px] h-[120px] sm:w-[520px] sm:h-[270px] md:w-[680px] md:h-[350px] lg:w-[490px] lg:h-[300px]`} */
  ${tw`mt-12 h-[150px] sm:h-[270px] md:h-[290px] lg:h-[300px]`}
  width: 200px;
  cursor: pointer;

  @media (min-width: 590px) {
    width: 270px; /* For extra small screens */
  }
  @media (min-width: 640px) {
    width: 520px; /* For small screens */
  }

  @media (min-width: 768px) {
    width: 520px; /* For medium (md) screens */
  }

  @media (min-width: 1024px) {
    width: 580px;
    height: 300px; /* For large (lg) screens */
  }

  @media (min-width: 1280px) {
    width: 420px; /* For extra large (xl) screens */
  }
`;
/* --------------------Styling present on Tags Card------------------------------------*/
export const TagHeader = styled.h3`
  ${tw`flex justify-center items-center pt-12`}
`;
export const TagBody = styled.div`
  ${tw`flex justify-center items-center flex-wrap pl-[8%] pr-[2%]`}
`;

export const TagBodySub = styled.div`
  ${tw`flex flex-wrap justify-center gap-3`}
`;

export const Tag = styled.div`
  ${tw`text-sm p-1 text-[#0056b3] m-1 cursor-pointer rounded`}
  border:1px solid #0056b3
`;

export const TagBtn = styled.button`
  ${tw` border-none py-1  text-sm  cursor-pointer text-white rounded bg-[#0056b3] duration-75`}
  margin:10px;
`;
export const TagHighLight = styled.div`
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  color: white;
  background-color: ${({ isActive }) => (isActive ? 'blue' : 'gray')};

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? 'darkblue' : 'lightgray'};
  }
`;
export const EventsTag = styled.div`
  ${tw`text-sm p-1 text-[#0056b3] m-2 cursor-pointer rounded `}
  border:2px solid #0056b3;
  width: 50%;
  box-sizing: border-box;
`;
/* -----------------------------Styling of Horizontal CARD------------------------------------- */
export const ButtonTag = styled.div`
  ${tw`shadow-md bg-white text-black rounded p-2 px-4`}
`;
export const HorizontalCardCont = styled.div`
  ${tw`w-full`}
`;
export const RegisterBttn = styled.button`
  ${tw`flex flex-wrap items-center justify-center`}
  background-color: #fff;
  color: #2d8df4;
  padding: 8px 14px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1.2em;
  margin-top: -7px;
  margin-left: 90px;
  &:hover {
    color: #007bff;
  }
`;
export const MainContainer = styled.div`
  margin-top: 5rem;
`;
export const StyledCardContainer = styled.div`
  margin-top: 1.5rem;
  margin-left: 160px;
  border-radius: 17px;
  background-color: #bcebd7;
`;

export const StyledDateContainer = styled.div``;

export const StyledDateBox = styled.div`
  flex: 0;
  width: 250px;
  margin-right: 20px;
  padding: 10px;
`;

export const StyledDateContent = styled.div`
  padding: 10px;
  border: 1px solid;
  background-color: #ffffff;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  max-height: 150px;
  overflow: hidden;
`;

export const StyledDateText = styled.p`
  margin-top: -10px;
  font-size: 30px;
  font-weight: 100;
`;

export const StyledMonthText = styled.p`
  font-size: 40px;
  font-weight: 500;
  margin-top: -70px;
`;

export const CardContent = styled.div``;
export const CardLowerCont = styled.div`
  margin-left: -7px;
`;
export const CardUpperCont = styled.div`
  margin-top: -10px;
`;
export const Type = styled.div``;

export const StyledTitle = styled.h3`
  margin-top: 24px;
  margin-bottom: 5px;
  display: inline-block;
  word-spacing: 0.5em;
  font-size: 20px;
`;

export const StyledDescription = styled.p`
  margin-bottom: 2px;
  max-width: 500px; /* Adjust the value as needed */
  overflow: hidden;
  font-size: '12px';
  word-spacing: 0.5em;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
`;

export const StyledPriceContainer = styled.div`
  padding: 6px;
  margin-left: -30px;
  display: inline-block;
`;

export const StyledPriceText = styled.p`
  /* margin-left: 30px; */
  font-size: 19px;
  margin-bottom: 90px;
  font-weight: 500;
`;

export const ButtonCont = styled.div`
  margin-top: auto;
`;

export const StyledEnrollButton = styled.div`
  ${tw` font-bold py-2 px-4`}
  border:none;
  /* margin-left: -60px; */
  border-radius: 10px;
  background-color: #389dfc;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;
`;
export const Day = styled.div``;

export const Time = styled.div`
  font-size: '20px';
  color: '#fff';
`;
export const Date = styled.div`
  color: '#fff';
`;

/* -----------------------------Styling of Card------------------------------------- */

export const Card = styled.div`
  ${tw`flex justify-start items-center flex-wrap m-[10px] h-[250px] border-[2px] border-black box-border rounded  overflow-hidden  w-[85%] sm:w-[60%] md:w-[40%] lg:w-[35%] xl:w-[25%]`}
  border: 2px solid gainsboro;
`;
/* --------------------Popup card styling on close---------------------------------------------- */

export const AddDiv = styled.div`
  ${tw`flex items-center justify-between m-2`}
  font-weight:400;
`;
export const CloseIconStyle = {
  background: `${palette.primary}`,
  borderRadius: '40px',
  padding: '4px',
  color: `${palette.white}`,
};
export const DialogBoxTitle = styled.div`
  ${tw`	`}
  font-weight:500;
`;

export const EventTime = styled.div``;

export const EventName = styled.div`
  font-size: '21px';
  margin-top: 10px;
  font-weight: 500;
`;

/* --------------------Popup card styling---------------------------------------------- */
export const PopupCont = styled.div``;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -25px;
`;
export const RegisterButton1 = styled.button`
  background-color: #fff;
  color: #007bff;
  padding: 5px 11px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: -20px;
  margin-left: 49px;
  font-size: large;
`;
export const Description = styled.div`
  /* ${`text-sm my-8`} */
  /* color:"##979DA3", */
`;

export const EvCardTitle = styled.div`
  margin-left: 2px;
  /* font-weight: 0.5rem; */
  margin-top: -23px;
`;
export const EvCard1 = styled(Card)`
  /* background-color: #D0D7FF;  */
  border: 1px solid;
  border-color: ${palette.gray};
  width: 240px;
  border-radius: 10px;
  height: 120px;
  padding-left: 6px;
  display: flex;
  /* flex-direction: column; */
  /* box-shadow: 6px 5px 5px rgba(0, 0, 0, 0.2); */
`;

export const EvCard2 = styled(Card)`
  border: 1px solid;
  border-color: ${palette.gray};
  /* background-color: #D0D7FF;  */
  width: 240px;
  border-radius: 10px;
  height: 120px;
  padding-left: 6px;
  display: flex;
`;

export const EvCard3 = styled(Card)`
  border: 1px solid;
  border-color: ${palette.gray};
  /* background-color: #D0D7FF;  */
  width: 240px;
  border-radius: 10px;
  height: 120px;
  padding-left: 6px;
  display: flex;
`;

export const EvCard4 = styled(Card)`
  border: 1px solid;
  border-color: ${palette.gray};
  /* background-color: #D0D7FF;  */
  width: 240px;
  border-radius: 10px;
  height: 120px;
  padding-left: 6px;
  display: flex;
`;

export const EvCard5 = styled(Card)`
  border: 1px solid;
  border-color: ${palette.gray};
  /* background-color: #D0D7FF;  */
  width: 240px;
  border-radius: 10px;
  height: 120px;
  padding-left: 6px;
  display: flex;
`;

export const EvCard6 = styled(Card)`
  border: 1px solid;
  border-color: ${palette.gray};
  /* background-color: #D0D7FF;  */
  width: 240px;
  border-radius: 10px;
  height: 120px;
  padding-left: 6px;
  display: flex;
`;
/* ---------------------Event page styling---------------------------------- */

export const EventTitle = styled.div`
  ${tw`flex items-center  pb-4 p-20`}
`;
export const BackArrow = styled.div`
  ${tw` mr-10`}/* margin-left: 30px; */
  /* margin-top: 20px; */
  /* width:20px; */
`;

export const Title = styled.div`
  ${tw` text-2xl font-bold `}
  margin-left:550px;
`;
export const EventCon1 = styled.div`
  ${tw`flex items-center justify-between p-16`}
  margin-top:-30px;
`;
export const InstructorPic = styled.img`
  width: 40px;
  height: 45px;
  margin-right: '10px';
  margin-top: '-20px';
  /* border-radius: 50%; */
  /* margin-top: 10px; */
`;

export const InstructorName = styled.div`
  ${tw`ml-4`}
`;
export const InstName = styled.div`
  ${tw`text-xl font-bold mb-4`};
  font-size: '30px';
`;

export const RightSide = styled.div`
  ${tw`w-1/2  `}
`;
export const LeftSide = styled.div`
  ${tw`w-1/2 pr-4 flex items-center`}
`;
export const LeftCont = styled.div`
  ${tw`flex items-center`}
`;

export const LeftContSub = styled.div``;

export const InstructorCont = styled.div`
  ${tw`flex items-center`}
`;
export const CancelButton = styled.div`
  ${tw``}
  ${tw`cursor-pointer bg-red-400  text-white  py-1 px-3 rounded-full ml-80 flex items-center justify-center`}
`;
export const StyledCloseIcon = styled(CloseIcon)`
  ${tw`mr-1`}
`;
export const DescriptionCont = styled.div`
  ${tw`p-4 `}
  margin-top:-60px;
`;

export const DescriptionTitle = styled.div`
  ${tw`p-4 ml-8 font-bold text-xl`}/* margin-top:-80px; */
`;

export const EventCon2 = styled.div`
  ${tw`flex justify-between p-16`}
  margin-top:-70px;
`;

export const LeftDown = styled.div`
  ${tw` pr-4 flex items-center mt-8`}
`;
export const RightDown = styled.div`
  ${tw` pl-10 items-center w-full`}
`;
export const MovePayment = styled.div`
  ${tw`cursor-pointer bg-blue-400 text-white  py-1 rounded-full flex items-center justify-center`}/* margin-left:5px; */
/* margin-top:"4px"; */
/* :hover {
    background-color: #0056b3;
  } */
`;

export const CalendarImg = styled.img`
  ${tw`w-6 h-6 mx-2`}
`;
export const CalendarButton = styled.div`
  margin-left: 170px;
`;

export const Para = styled.div`
  font-weight: 500;
  ${tw`my-6`}
`;

export const EventHeading = styled.div`
  ${tw`text-xl font-bold`}
  margin-top: 20px;
`;
export const EventDetailsDescription = styled.div``;

export const TagsCont = styled.div`
  max-width: 47%;
`;

export const Tags = styled.div`
  ${tw`text-sm p-1 text-[#0056b3] m-2 cursor-pointer rounded`}
  border:2px solid #0056b3;
  box-sizing: border-box;
`;
export const List = styled.ul`
  /* list-style-type: none; */
  padding: 0;
`;

export const ListItem = styled.li`
  font-weight: 400;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: 2;
`;

export const EventTag = styled.div`
  ${tw`text-sm p-1 text-[#0056b3] m-2 cursor-pointer rounded `}
  border:2px solid #0056b3;
  width: 45%;
  box-sizing: border-box;
`;
export const ShareCont = styled.div`
  ${tw`flex flex-wrap items-center justify-center`}
`;

export const SocialCont = styled.div`
  ${tw`flex items-center ml-4`}
`;

export const Social = styled.img`
  ${tw`w-6 h-6 mx-2 cursor-pointer`}
`;
export const LearningOutcomesGrid = styled.div`
  /* ${tw`flex flex-col items-start justify-start`} */
  width: 100%; // Adjust the width as needed
  margin-top: 20px;
  margin-left: -5px;
`;
/* ---------------------Announcement styling---------------------------------- */
export const AnnouncementTable = styled.div`
  margin-top: 30px;
  padding: 15px;
`;
export const ArchieveTable = styled.div`
  margin-top: 30px;
  padding: 15px;
`;

export const AddAnnoucementCont = styled.div`
  ${tw`p-10`}
  margin-top: '-15px';
`;
export const AnnouncementCont = styled.div`
  ${tw`relative`}
  margin-top:40px;
  margin-bottom: 20px;
`;
export const ArchievedCont = styled.div`
  ${tw`relative`}
  margin-top:-5px;
  margin-bottom: 20px;
`;
export const AnnouncementButton = styled.div`
  ${tw``}
  margin-left:68rem;
  margin-top: -30px;
  @media (max-width: 768px) {
    margin-left: 35rem;
    margin-top: 10px;
  }
`;

export const AnnouncementHeader = styled.div`
  margin-left: 10px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
`;
export const ArchieveLink = styled.a`
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 10px;
`;
export const ArchieveBttn = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: blue;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  background-color: white;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

/* ---------------------AnnouncementDetails styling---------------------------------- */
export const Head = styled.div`
  ${tw`flex`}
`;
export const Details = styled.div`
  ${tw`m-4`}
`;
export const ImgSection = styled.div`
  ${tw`flex flex-col items-center `}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;
export const Info = styled.div`
  ${tw``}
`;
export const AnnImg = styled.img`
  padding: 10px;
  width: 470px;
  height: 300px;
`;
export const Profile = styled.div`
  ${tw`flex m-auto justify-between m-auto w-11/12 items-center p-4 `}
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;
/* export const Head = styled.div``; */

/* ---------------------UserInfo styling---------------------------------- */

export const UserInfoCont = styled.div`
  ${tw`relative`}
  margin-top:10px;
  margin-bottom: 20px;
`;
export const NotdonePayment = styled.button`
  background-color: #fff;
  color: red;
  border: none;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid red;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: red;
  }
`;
export const ApprovedPayment = styled.button`
  background-color: #fff;
  color: green;
  border: none;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid green;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: green;
  }
`;
export const CompletedPayment = styled.button`
  background-color: #fff;
  color: blue;
  border: none;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid blue;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: blue;
  }
`;

/* ---------------------Enrolled User styling---------------------------------- */

export const FilterContainer = styled.div``;
export const TableGrid = styled.div``;

export const HeaderCont = styled.div`
  display: 'flex';
  align-items: 'center';
`;
export const EnrollButton = styled.div`
  margin-left: 65rem;
  @media (max-width: 768px) {
    margin-left: 35rem;
    margin-top: 10px;
  }
`;
export const UserTable = styled.div`
  padding: '25rem';
`;

export const CardImg = styled.img`
  width: 75%;
  height: 80px;
  object-fit: contain;
`;
export const ActiveBttn = styled.button`
  background-color: #fff;
  color: green;
  border: none;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid green;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: green;
  }
`;

export const InactiveBttn = styled.button`
  background-color: #fff;
  color: red;
  border: none;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid red;
  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: red;
  }
`;
