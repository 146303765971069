// import React, { useState, useEffect } from "react";
// import firebase from "firebase/compat/app";
// import Button from '@mui/material/Button';
// import { useNavigate } from "react-router-dom";
// import { useDispatch , useSelector} from "react-redux";
// import {logInUser } from "../features/authentication/AuthenticationSlice"

// const PageNotFound = () => {
//   const dispatch = useDispatch()

//     const navigate = useNavigate();
//     useEffect(() => {
//       firebase.auth().onAuthStateChanged((userCred) => {
//         if (userCred) {
//           console.log("userCred ds" ,userCred);
//           userCred.getIdToken().then((token) => {
//            dispatch(logInUser(token)).then( (userData)=>{
//             console.log("userData dd" , userData)
//             if (userData?.payload?.statusCode === 200) {
  
//               navigate("/event");
//             } else {
//               alert(userData?.payload?.message)
//               navigate("/");
//             }  })
  
//           });
//         }
//       });
//     }, []);
//   return (
//     <div>
//       Page Not Found
// <Button onClick={ ( ) => navigate('/')}>Back to login</Button>
//     </div>
//   )
// }

// export default PageNotFound


import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logInUser } from "../features/authentication/AuthenticationSlice";
import Button from '@mui/material/Button';
import HomePageLoader from '../Loader/HomaPageLoader'

const PageNotFound = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((userCred) => {
      if (userCred) {
        userCred.getIdToken().then((token) => {
          dispatch(logInUser(token)).then((userData) => {
            if (userData?.payload?.statusCode === 200) {
              setIsLoggedIn(true); // User is logged in
              navigate("/event");
            } else {
              setIsLoggedIn(false); // User is logged in but not authorized
              navigate("/");
            }
          });
        });
      } else {
        setIsLoggedIn(false); // User is not logged in
        navigate("/");
      }
    });
  }, []);

  // Conditionally render the "Page Not Found" message based on isLoggedIn state
  if (!isLoggedIn) {
    return (
      <div>
        {/* Page Not Found
        <Button onClick={() => navigate("/")}>Back to login</Button> */}
        <HomePageLoader/>
      </div>
    );
  }

  // If the user is logged in and authorized, return null to prevent rendering the message
  return null;
};

export default PageNotFound;
