// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyDQ2YeJbj0MYK7kcJsg5V5uqlDYyJA92Us",
  // authDomain: "ganesh-94c4b.firebaseapp.com",
  // projectId: "ganesh-94c4b",
  // storageBucket: "ganesh-94c4b.appspot.com",
  // messagingSenderId: "567159704549",
  // appId: "1:567159704549:web:98ad38d36ede7934f7bd0c",
  // measurementId: "G-PGZCHCF455"
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();