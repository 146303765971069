// import React, { useState } from "react";
// import { Tab, Box } from "@mui/material";
// import UserDetails from "../Users/UserDetails";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
// import EventDetails from "./Event";
// const NavTab = () => {
//   const [value, setValue] = React.useState("1");

//   const handleChange = (event, newValue) => {
//     console.log(newValue);
//     setValue(newValue);
//   };
//   return (
//     <Box sx={{ width: "100%", typography: "body1" }}>
//       <TabContext value={value}>
//         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//           <TabList onChange={handleChange} aria-label="lab API tabs example">
//             <Tab label="Event Details" value="1" />
//             <Tab label="User Details" value="2" />
//           </TabList>
//         </Box>
//         {/* <TabPanel value="1">
//           {value === "1" && <EventDetails />}
//         </TabPanel> */}
//         {/* <TabPanel value="2">
//           {value === "2" && <UserDetails />}
//         </TabPanel> */}
//       </TabContext>
//     </Box>
//   );
// };

// export default NavTab;

import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  StyledTabs,
  StyledTab,
} from '../Styles/NavTabStyles';

const NavTab = () => {
  const location = useLocation();
  const defaultTabValue = ["/event","/UserDetails"].includes(location.pathname) ? location.pathname : "/event";

  return (
    <StyledTabs value={defaultTabValue}>
       <StyledTab
        label="Event Details"
        value="/event"
        component={Link}
        to="/event"
      />
      <StyledTab
        label="User Details"
        value="/UserDetails"
        component={Link}
        to="/UserDetails"
      />
      
    </StyledTabs>
  );
};

export default NavTab;
