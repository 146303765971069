import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginUserApi ,logInUserWithEmilApi } from "../../API/AuthenticationApi";

export const logInUser = createAsyncThunk(
    "logInUser",
    async(data,{rejectWithValue}) =>{
      try {
        const result = await loginUserApi(data);
        console.log("new user loginUserApi : ", result);
        console.log("hii from redux")
        return result.data
      } catch (error) {
        console.log("redux errpor" , error)
        return rejectWithValue(error.response.data);
      }
  
    }
)

export const logInUserWithEmil = createAsyncThunk(
  "logInUserWithEmil",
  async(data,{rejectWithValue}) =>{
    try {
      const result = await logInUserWithEmilApi(data);
      console.log("new user from logInUserWithEmilApi : ", result);
      console.log("hii from redux")
      return result.data
    } catch (error) {
      console.log("redux errpor" , error)
      return rejectWithValue(error.response.data);
    }
  }
)

export const logOutUser = createAsyncThunk(
  "logOutUser",
  async(data,{rejectWithValue}) =>{
      const result = "";
      return result
     
  }
)


export const Authentication = createSlice({
    name: "Authentication",
    initialState: {
      user: {},
      loading: false,
      error: null,
      searchData: [],
    },
  
    reducers: { },
  
    extraReducers: (builder) => {
      builder
        .addCase(logInUser.pending, (state) => {
          state.loading = true;
        })
        .addCase(logInUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
        })
        .addCase(logInUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(logInUserWithEmil.pending, (state) => {
          state.loading = true;
        })
        .addCase(logInUserWithEmil.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
        })
        .addCase(logInUserWithEmil.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(logOutUser.pending, (state) => {
          state.loading = true;
        })
        .addCase(logOutUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = "";
        })
        .addCase(logOutUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        })
      
      }

    // extraReducers: {
    //   [logInUser.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [logInUser.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     state.users = action.payload;
    //   },
    //   [logInUser.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload.message;
    //   },
      
    //   [logOutUser.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [logOutUser.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     state.users = "";
    //   },
    //   [logOutUser.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload.message;
    //   },

    // },
  });
  
  export default Authentication.reducer;
  
//   export const { searchUser } = userDetail.actions;
  