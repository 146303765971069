export const palette = {
    // primary: "#FF9900",
    // secondary: "#33CCCC",
    // tertiary: "#317AE7",
    // white: "#ffffff",
    // black: "#000000",
    // gray: "#808080",
    // light_gray: "#F7F7F7",
    // footer: "#F7F7F7",
    // heading: "#0A0959",
    // form_bg: "#F6F8FF",
    bgColor1: "#F3F0EC",
    bgColor2: "#FFFFF0",
    bgColor3: "#F9F8F8",
    newColor: "#f2f6f9",
    primary: "#0684FB ",
    secondary: "#036ACB",
    tertiary: "#389DFC",
    heading: "#0A0959",
    gray: "#CCCCCC",
    light_gray: "#E6E6E6",
    white: "#ffffff",
    black: "#000000",
    buttonhover: "#6AB6FD",
    success: "#007E33",
    failure: "#CC0000",
    moderate: "#FF8800", 
    evenrow_bg: "#EFF8FF",
    Tablerow_hover: "#D9EFFF",
    TableIcon: "#005FA4"

  };
  
  export const theme = {
    themeBorderRadius: "25px",
  };
  

