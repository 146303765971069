import {configureStore} from '@reduxjs/toolkit';
import eventDetails from '../features/eventSlice';
import userDetails from '../features/userSlice';
import instructorDetails from '../features/instructorSlice';
import authDetails from '../features/authentication/AuthenticationSlice';
import userModuleSlice from '../features/userModuleSlice';

export const store = configureStore ({
    reducer:{
        app: eventDetails,
        user: userDetails,
        inst: instructorDetails,
        auth : authDetails,
        usersmod:userModuleSlice
    },
});
 