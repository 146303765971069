import React from "react";
import DataTable from "react-data-table-component"; // Import your DataTable library
// import { palette } from '../../styles/palette';
import Chip from "@mui/material/Chip"; // Import Chip component from Material-UI
import { palette } from "../Styles/Palette";
const TableUtilityLib = (props) => {
  const handleRowClick = (row) => {
    if (props.onRowClicked) {
      props.onRowClicked(row);
    }
  };

  const customStyles = {
    table: {
      style: {
        border: "2px solid #ddd",
        borderRadius: "10px", // Slightly larger border radius for a softer look
        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.2)", // Add subtle shadow for depth
      },
    },
    headRow: {
      style: {
        backgroundColor: "#f8f9fa", // Slightly lighter header row background color
        color: palette.primary,
        fontWeight: "800", // Use a medium font weight for better readability
        fontSize: "16px", // Slightly larger font size for headers
        borderBottom: "2px solid #dee2e6", // Add a border below the header row
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        textAlign: "center", // Center align header cells
        fontFamily: "Roboto, sans-serif",
      },
    },
    cells: {
      style: {
        justifyContent: "center", // Center align cells horizontally
        textAlign: "center", // Center align cell content
        fontSize: "14px",
        fontWeight: "550", // Font size for table cells
        borderBottom: "1px solid #e9ecef", // Add border between rows for separation
        fontFamily: "Roboto, sans-serif",
      },
    },
    highlightOnHover: {
      style: {
        backgroundColor: "#f1f3f5", // Slightly different background color on hover
        cursor: "pointer", // Change cursor to pointer on hover for better UX
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.newlyAdded,
      style: {
        backgroundColor: "#d4d",
        "&:hover": {
          backgroundColor: "#c8e6c9", // Slightly darker green when hovered
        },
      },
    },
  ];

  const columns = props.columns.map((column) => {
    if (column.id === "verified") {
      return {
        ...column,
        cell: (row) =>
          column.selector(row) === "Verified" ? (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: "green",
                color: "green",
                width: "100px",
                textTransform: "capitalize",
                fontSize: "12px",
                textAlign: "center", // Center align Chip text
              }}
            />
          ) : (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: "red",
                color: "red",
                width: "100px",
                textTransform: "capitalize",
                fontSize: "12px",
                textAlign: "center", // Center align Chip text
              }}
            />
          ),
      };
    }
    if (column.id === "blacklist") {
      return {
        ...column,
        cell: (row) =>
          column.selector(row) === "Blacklisted" ? (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: "red",
                color: "red",
                width: "110px",
                textTransform: "capitalize",
                fontSize: "12px",
                textAlign: "center", // Center align Chip text
              }}
            />
          ) : (
            <Chip
              size="small"
              label={column.selector(row)}
              variant="outlined"
              style={{
                borderColor: "#DBDBDB",
                color: "#000000",
                width: "110px",
                textTransform: "capitalize",
                fontSize: "12px",
                textAlign: "center", // Center align Chip text
              }}
            />
          ),
      };
    }
    return column;
  });

  return (
    <DataTable
      title={props.title}
      columns={columns} // Use the modified columns array
      data={props.data}
      highlightOnHover
      pointerOnHover
      customStyles={customStyles}
      // selectableRowsHighlight
      contextActions={props.contextActions}
      onRowClicked={(row) => handleRowClick(row)}
      pagination
    />
  );
};

export default TableUtilityLib;
