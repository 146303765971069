import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {createInstructorApi, fetchInstructorApi} from '../API/api';
import axios from 'axios';


// Define the initial state
const initialState = {
  instDetail: [],
  status: 'idle',
  error: null,
};

export const createInstructor = createAsyncThunk(
    'createUser',
    async ({ Name: instructorName }, { rejectWithValue }) => {
      try {
        return await createInstructorApi({ Name: instructorName });
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


  export const fetchInstructor = createAsyncThunk(
    'fetchInstructor',
    async (data, { rejectWithValue }) => {
      try {
        return await fetchInstructorApi();
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );




const instructorSlice = createSlice({
    name: 'instructorDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(createInstructor.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createInstructor.fulfilled, (state, action) => {
          state.status = 'idle';
          state.instDetail.push(action.payload);
        })
        .addCase(createInstructor.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })

      //fetch Instructor  
      .addCase(fetchInstructor.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInstructor.fulfilled, (state, action) => {
        state.status = 'idle';
        state.instDetail = action.payload; 
      })
      .addCase(fetchInstructor.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    },
  });
  
  export default instructorSlice.reducer;
  