import styled from 'styled-components';
import { palette } from './Palette';
import tw from 'twin.macro';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const StyledTabs = styled(Tabs)`
background-color: ${palette.light_gray};
${tw`fixed z-50 w-full`}
top: 80px;

.MuiButtonBase-root.MuiTab-root {
  background:${palette.light_gray};
  border-radius: 8px 8px 0 0;

  &:hover {
    text-decoration: none;
  }
  
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  background-color: ${palette.white};
  z-index: 10;
}

.MuiTabs-indicator {
  display: none;
}

@media (max-width: 768px) {
  .MuiButtonBase-root.MuiTab-root {
    --tab-width: 100%; /* Full width on smaller screens */
  }
}

@media (min-width: 769px) {
  .MuiButtonBase-root.MuiTab-root {
    --tab-width: calc(100% / 3); /* Divide equally into 3 parts on larger screens */
  }
}`;

export const StyledTab = styled(Tab)
``;