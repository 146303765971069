import styled from 'styled-components';
import tw from 'twin.macro';
import { palette } from '../Palette';
// import { palette } from '../palette';

export const HistoryPage = styled.div`
  min-height: 75vh;
  ${tw`w-11/12 m-auto`}
`;
export const FrontPage = styled.div`
  min-height: 75vh;
`;

export const UserTable = styled.div`
  ${tw`m-auto`}
  margin-top: 1px;
`;

export const Text = styled.p`
  ${tw`text-sm sm:text-base tracking-normal sm:tracking-wider leading-relaxed text-justify md:text-left`}
`;

export const AddButton = styled.button`
  ${tw`ml-2`}
  ${tw`rounded text-lg md:text-base py-2 px-4  border-none transition-all duration-300 cursor-pointer`}
  color: ${palette.white};
  background-color: ${palette.tertiary};
  &:hover {
    background-color: ${palette.buttonhover};
  }
`;
export const Header = styled.div``;
export const HeaderContain = styled.div`
  margin-left: -59px;
`;

export const VerifyContainer = styled.div`
  border: 0px solid red;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PasswordContainer = styled.div`
  border: 0px solid red;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ForgetPassContainer = styled.div`
  border: 0px solid red;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UserHistoryTitle = styled.div`
  margin-top: 70px;
  margin-bottom: 37px;
  ${tw`flex items-center  ml-16`}
`;
export const SearchBar = styled.div`
  margin-left: 55px;
  display: flex;
  justify-content: space-between;
  ${tw`flex justify-between items-center gap-2 mb-4`}
`;

export const SearchBarContainer = styled.div``;
