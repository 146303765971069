import axios from 'axios';

// const process.env.REACT_APP_URL = 'https://leapot-lms-backend.onrender.com/api';
// const URL= 'http://localhost:8000/api'

export const loginUserApi = async(token) => {
    try{
        console.log(token);
    //    return await axios.post(`${process.env.REACT_APP_URL}/login`,data);
    return await axios.get(`${process.env.REACT_APP_URL}/api/eventManager/signIn`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    catch(err){
        console.log("Error occurs while running loginUser function",err);
    }
};



export const logInUserWithEmilApi = async(token) => {
  try{
      console.log(token);
  //    return await axios.post(`${process.env.REACT_APP_URL}/login`,data);
  return await axios.post(`${process.env.REACT_APP_URL}/api/loginWithEmail`, {
    headers: {
    Authorization: 'Bearer ' + token,
  },
});
  }
  catch(err){
      console.log("Error occurs while running loginUser function",err);
  }
};