import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import {store } from "./app/store";
import { Provider } from "react-redux";
import "./config/Firebase-config";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    {/* <GoogleOAuthProvider clientId="789202102394-3mcptsqb35tpnj38l0cv9l1jnrf4hkio.apps.googleusercontent.com"> */}
    <App />
    {/* </GoogleOAuthProvider> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
