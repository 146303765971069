import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createEventApi, fetchEventApi, fetchEventDetailsApi,updateEventApi, deleteEventApi, viewEventDetailsApi } from '../API/api';


// Define the initial state
const initialState = {
  events: [],
  status: 'idle',
  error: null,
};

export const createEvent = createAsyncThunk(
    'createEvent',
    async (formData, { rejectWithValue }) => {
      try {
        return await createEventApi(formData);
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  
  export const fetchEvent = createAsyncThunk(
    'fetchEvent',
    async (data, { rejectWithValue }) => {
      try {
        return await fetchEventApi();
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


// get updated event
export const fetchEventDetails = createAsyncThunk(
    'fetchEventDetails',
    async (id, { rejectWithValue }) => {
      try {
        const data = await fetchEventDetailsApi(id);
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  //update action
  export const updateEvent = createAsyncThunk(
    'upadateEvent', 
    async (data, { rejectWithValue, dispatch }) => {
      try {
        const response = await updateEventApi(data);
        dispatch(fetchEvent());
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

//delete action
export const deleteEvent = createAsyncThunk(
    'deleteEvent',
    async (id, { rejectWithValue, dispatch }) => {
      console.log("delete event id", id)
      try {
        await deleteEventApi(id);
        dispatch(fetchEvent());
        return id; // Return the eventId if deletion is successful
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );


// view event details
export const viewEventDetails = createAsyncThunk(
    'viewEventDetails',
    async (id, { rejectWithValue }) => {
      try {
        const data = await viewEventDetailsApi(id);
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

// Create a slice
const eventSlice = createSlice({
  name: 'eventDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events.push(action.payload);
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
//fetch
      .addCase(fetchEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload;
      })
      .addCase(fetchEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
//fetch event details 
      .addCase(fetchEventDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventDetails.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload; 
      })
      .addCase(fetchEventDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

//update
      .addCase(updateEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

//delete
      .addCase(deleteEvent.pending, (state) => {
        state.status = 'loading';
      })
      // Add a case for successful deletion
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.status = 'idle';

        const {id} = action.payload;
        if(id){
            state.events = state.events.filter((event) => event._id !== id);

        }
        // Remove the deleted event from the events array
      })
      // Add a case for deletion failure
      .addCase(deleteEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // view event details 
      .addCase(viewEventDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(viewEventDetails.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload; 
      })
      .addCase(viewEventDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

  },
});

export default eventSlice.reducer;
