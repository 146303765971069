import axios from 'axios';

const URL = process.env.REACT_APP_URL;

export const fetchUserApi = async () => {
  try {
    const response = await axios.get(`${URL}/api/usermodule/getusersdetails`);
    return response.data;
  } catch (error) {
    console.error('Error fetching users', error);
    throw error;
  }
};

export const createUserApi = async (userData) => {
  try {
    const response = await axios.post(
      `${URL}/api/usermodule/createnewusers`,
      userData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    console.log('createUser response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating users', error);
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data;
    } else {
      throw new Error('An error occurred while creating the user.');
    }
  }
};

export const createUserByCSVApi = async (csvData) => {
  try {
    const response = await axios.post(
      `${URL}/api/usermodule/usersCsv`,
      csvData,
    );
    return response.data;
  } catch (error) {
    console.error('Error creating users by csv', error);
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Failed to import CSV');
    } else {
      throw new Error('An error occurred while importing the CSV.');
    }
  }
};

export const updateUserApi = async (id, userdetails) => {
  try {
    const response = await axios.put(
      `${URL}/api/usermodule/updateUserProfile/${id}`,
      userdetails,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user details', error);
    throw error;
  }
};

export const fetchUserDetailsApi = async (id) => {
  try {
    return await axios.get(`${URL}/api/usermodule/getUserdetails/${id}`);
  } catch (err) {
    console.log('Error while fetching user details by id', err);
    throw err;
  }
};

export const deleteUserApi = async (id) => {
  try {
    const response = await axios.put(`${URL}/api/usermodule/deleteUser/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error logically deleteing user', error);
    throw error;
  }
};

export const fetchUserDetailsbyRoleApi = async (role) => {
  try {
    return await axios.get(`${URL}/api/usermodule/getusersdetails/${role}`);
  } catch (err) {
    console.log('Error while fetching user details by role', err);
    throw err;
  }
};

export const fetchUserRoleApi = async () => {
  try {
    const response = await axios.get(`${URL}/api/fetchRole`);
    return response.data;
  } catch (err) {
    console.log('Error while fetching role', err);
    throw err;
  }
};

export const passwordResetApi = async (email) => {
  try {
    const response = await axios.post(
      `${URL}/api/usermodule/passwordResetLink`,
      {
        email,
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      'Error in passwordResetApi:',
      error.response || error.message,
    );
    throw error;
  }
};

export const fetchUserHistoryApi = async () => {
  try {
    return await axios.get(`${URL}/api/usermodule/getuserhistory`);
  } catch (err) {
    console.log('Error occurs while fetching history', err);
    throw err;
  }
};
