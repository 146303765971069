import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import Logo_5 from "../Images/Logo_5.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Navbar,
  NavLogo,
  NavTitle,
  ProfileDiv,
  ProfileButton,
  ProfileSection,
} from "../Styles/NavbarStyles";
import {
  logInUser,
  logOutUser,
} from "../features/authentication/AuthenticationSlice";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";

function EventNavbar() {
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state) => state.auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null); // Ref for the menu div

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = async () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.removeItem("auth");
        window.localStorage.removeItem("userDetails");
        dispatch(logOutUser());
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleUserDetailsClick = () => {
    navigate("/user-details");
  };

  const handleLogout = () => {
    localStorage.removeItem("email");
    navigate("/");
  };

  return (
    <Navbar style={{ position: "fixed", zIndex: "100" }}>
      <NavLogo src={Logo_5} alt="png" onClick={() => navigate("/event")} />
      <NavTitle>Event Hub</NavTitle>
      <div
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          gap: "2px",
        }}
        ref={menuRef} // Reference to the div
      >
        <ProfileSection>
          <Avatar
            src={user?.user?.userid?.picture}
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "42px",
            }}
            onClick={toggleMenu}
          />
          <div style={{ color: "white" }}>{user?.user?.userid?.username} </div>
        </ProfileSection>
        {isMenuOpen ? (
          <KeyboardArrowUpIcon
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "32px",
            }}
            onClick={toggleMenu}
          />
        ) : (
          <KeyboardArrowDownIcon
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "32px",
            }}
            onClick={toggleMenu}
          />
        )}
        {isMenuOpen && (
          <ProfileDiv>
            <ProfileButton onClick={() => navigate("/profile")}>
              Profile
            </ProfileButton>
            <ProfileButton onClick={logout}>Logout</ProfileButton>
          </ProfileDiv>
        )}
      </div>
    </Navbar>
  );
}

export default EventNavbar;
