import React from 'react';
import Grid from '@mui/material/Grid';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from '@mui/material/Skeleton';

const TableHeaderRow = ({ columnWidths }) => {
    return (
        <TableRow>
            {columnWidths.map((width, index) => (
                <TableCell key={index}>
                    <Skeleton animation="wave" variant="text" width={width} height={35} />
                </TableCell>
            ))}
        </TableRow>
    );
};

const TableRowsLoader = ({ rowsNum, columnsNum, columnWidths }) => {
    return (
        <>
            <Skeleton animation="wave" variant="text" width={170} height={45} />
            <TableHeaderRow columnWidths={columnWidths} />
            {[...Array(rowsNum)].map((row, index) => (
                <TableRow key={index}>
                    {columnWidths.map((width, colIndex) => (
                        <TableCell key={colIndex}>
                            <Skeleton animation="wave" variant="text" width={width} height={20} />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
            <Grid container justifyContent="flex-end" spacing={1}>
            
                       
                        <div style={{ display:"flex" , justifyContent:"flex-end" , paddingRight: "7px" , gap : "5px" } }>

<Skeleton animation="wave" variant="text" width={105} height={35} />
<Skeleton animation="wave" variant="text" width={25} height={35} />
<Skeleton animation="wave" variant="text" width={40} height={35} />
<Skeleton animation="wave" variant="text" width={85} height={35} />

    </div>
                    </Grid>
                
            
        </>
    );
};

export default TableRowsLoader;
