import React from 'react';
import { Skeleton } from '@mui/material';
import TableLoading from './TableLoading'

const EventManagerSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
     
      
      <Skeleton variant="text" width= "100%" height={120} />
        
      
      <div style={{ display: 'flex' , gap : "3px", marginRight:"8px" , marginLeft:"8px", marginTop:"0px" }}>
        <Skeleton variant="text" width={200} height={55} />
        <Skeleton variant="text" width={200} height={55} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'right', marginRight:"8px" , marginLeft:"8px" ,marginTop: "15px" }}>
        <Skeleton variant="rectangular" width={250} height={40} style={{marginRight:"50px"}} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'right',marginRight:"8px" , marginLeft:"8px", marginTop: 20 , gap: "3px"}}>
        <Skeleton variant="text" width={110} height={50} />
        <Skeleton variant="text" width={110} height={50} style={{marginRight:"40px"}} />
      </div>

      <div style={{ display: 'flex', gap: "3px", margin:"8px" }}>
        <Skeleton variant="text" width={420} height={50} />
        
      </div>

      <div style={{ display: 'flex', gap: "3px" ,marginRight:"8px" , marginLeft:"8px", marginLeft:"13px" }}>
        <Skeleton variant="text" width={120} height={50} />
        
      </div>
      <div style={{marginRight:"8px" , marginLeft:"8px"}}>

      <TableLoading rowsNum={5} columnsNum={5} columnWidths={[20, 275, 275, 275,275]} />
      </div>

    </div>
  );
};

export default EventManagerSkeleton;
